import { Injectable, Injector } from '@angular/core';
import * as minimongo from 'minimongo';
import { environment } from '../environments/environment';

// var minimongo = require('minimongo')

@Injectable({
  providedIn: 'root'
})
export class StorageNosqlService {

  public db_instance: any

  constructor(
    private injector: Injector,
  ) {
    console.log('Starting NoSql Init')
  }

  init() {
    return new Promise((resolve, reject) => {
      environment.storageNamespaceKeys.forEach((namespace) => {
        this.initDBNamespace(namespace).then((db) => {
          this.db_instance = db
          environment.storageCollectionKeys.forEach((collection) => {
            this.initDBCollection(collection).then(() => {
              resolve(true)
            })
          })
        })
      })
    })
  }

  // Seeding process
  initDBNamespace(collection: any) {
    return new Promise((resolve, reject) => {
      // initiliaze collection from environment variable - Use indexed db
      let instance = minimongo.IndexedDb;

      let db = new instance({ namespace: collection }, () => {
        // Successfully init namespace
        resolve(db)
      }, (error: any) => {
        reject('Failed to create namespace')
        console.log('Error raising error')
      })
    })
  }

  initDBCollection(collection: any) {
    // console.log(collection)
    return new Promise((resolve, reject) => {
      // Check for db collection
      // console.log(this.db_instance.getCollectionNames())
      if (collection in this.db_instance.getCollectionNames() == false) {
        // Create collection
        this.db_instance.addCollection(collection, () => {
          resolve(true)
        }, () => {
          reject('Failed to init collection')
        })
      }
    })
  }

  getDbInstance() {
    return this.db_instance
  }

  fetchCollectionInstance(collection) {
    return new Promise((resolve, reject) => {
      try {
        resolve(this.db_instance[collection])
      } catch (err) {
        const errorMessage: ErrorEventInit = {
          error: new Error('Schema Not Found'),
          message: "Requested schema instance is not found. Please initilize the schema.",
          lineno: 125,
          filename: 'StorageNoSql.ts'
        }

        throw new ErrorEvent('StorageNoSql', errorMessage)
      }
    })
  }

  clearCollection(collection) {
    // Remove collection
    return new Promise((resolve, reject) => {
      this.db_instance.removeCollection(collection, () => {
        resolve(true)
      }, () => {
        reject('Failed to remove collection')
      })
    })
  }


}
