import { Injectable } from '@angular/core';
import { Auth, User, UserProfile, UserProfileType } from '../models/auth.model';
import { StorageNosqlService } from './storage-nosql.service';
import { ObjectMapperService } from './object_mapper.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalvarService {

  public auth_global: Auth
  public user_global: User
  public user_profile: UserProfile

  constructor(
    private storage: StorageNosqlService,
    private objectMapper: ObjectMapperService
  ) {
    console.log('Init global var')
  }

  init() {
    return new Promise((resolve, reject) => {
      // Get all global variables
      this.auth_global = this.objectMapper.getdocschema('Auth')
      this.user_global = this.objectMapper.getdocschema('User')
      this.user_profile = this.objectMapper.getdocschema('UserProfile')
      Promise.all([this.getAuthData(), this.getUserData(), this.getProfileData()]).then(() => {
        resolve(true)
      }).catch(() => {
        reject(false)
      })
    })
  }

  clearGlobalData() {
    return new Promise((resolve, reject) => {
      this.auth_global = this.objectMapper.getdocschema('Auth')
      this.user_global = this.objectMapper.getdocschema('User')
      this.user_global = this.objectMapper.getdocschema('UserProfile')
      // Delete session entity
      resolve(true)
    })
  }

  getAuthData() {
    return new Promise((resolve, reject) => {
      this.storage.db_instance.Auth.findOne({ schema_doctype: 'Auth' }, ((data: Auth) => {
        this.auth_global = { ...data }
        resolve(true)
      }), () => {
        reject(false)
      })
    })
  }

  getUserData() {
    return new Promise((resolve, reject) => {
      this.storage.db_instance.User.findOne({ schema_doctype: 'User' }, ((data: User) => {
        // console.log(data)
        this.user_global = { ...data }
        resolve(true)
      }), () => {
        // Error Logging here
        reject(false)
      })
    })
  }

  getProfileData() {
    return new Promise((resolve, reject) => {
      this.storage.db_instance.UserProfile.findOne({ schema_doctype: 'UserProfile' }, ((data: UserProfile) => {
        this.user_profile = { ...data }
        resolve(true)
      }), () => {
        reject(false)
      })
    })
  }

  setAuthData(auth) {
    this.auth_global = { ...auth }
  }

  setUserdata(user) {
    this.user_global = { ...user }
  }

  setUserProfile(userProfile: UserProfileType) {
    this.user_profile = { ...userProfile }
  }

}
