import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { StorageNosqlService } from '../services/storage-nosql.service';
import { storageNoSql_Provider } from '../providers/storage-nosql.provider';
import { ORDERED_APP_INITIALIZER, orderedAppInitializer } from 'ngx-ordered-initializer';
import { environment } from '../environments/environment';
import { GlobalvarService } from '../services/globalvar.service';
import { globalvar_provider } from '../providers/globalvar.provider';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { ToastService } from '../services/toast.service';
import { toast_Provider } from '../providers/toast.provider';
// import { NgxPaginationModule } from 'ngx-pagination'

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ToastModule,
    HttpClientModule,
    AppRoutingModule,
    NgbModule,
    // NgxPaginationModule
  ],
  bootstrap: [AppComponent],
  providers: [
    MessageService,
    { provide: 'ENVIRONMENT', useValue: environment },
    { provide: ORDERED_APP_INITIALIZER, useFactory: storageNoSql_Provider, deps: [StorageNosqlService], multi: true },
    { provide: ORDERED_APP_INITIALIZER, useFactory: globalvar_provider, deps: [GlobalvarService], multi: true },
    { provide: APP_INITIALIZER, useFactory: orderedAppInitializer, deps: [ORDERED_APP_INITIALIZER], multi: true }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
