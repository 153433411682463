export const environment: environmentType = {
    production: false,
    loggerActive: false,
    type: 'desktop',
    // apiUrl: 'https://leasing.api.tektician.dev',
    apiUrl: 'https://sonarqube.tektician.com',
    // apiUrl: 'http://127.0.0.1:3000',
    hostUrl: 'https://sag.fortunebit.com',
    storageCollectionKeys: ["Auth", "User", "UserProfile"],
    storageKeysToInitialized: [{ 'key': 'isLogin' }, { 'key': 'email' }, { 'key': 'authUser', 'jsonparse': true }, { 'key': 'userRole' }, { 'key': 'employeeID' }, { 'key': 'access_token' }],
    storageNamespaceKeys: ["sag"],
    appVersion: '1.0.14',
    appVersionMock: '1.0.14',
};

type environmentType = {
    production: boolean,
    apiUrl: string,
    hostUrl: string,
    storageKeysToInitialized: Array<any>,
    storageNamespaceKeys: Array<any>,
    storageCollectionKeys: Array<any>,
    loggerActive: boolean,
    type: string,
    appVersion: string,
    appVersionMock: string,
}
