import { Injectable } from '@angular/core';
import { Auth, Login, User, UserProfile } from '../models/auth.model';
import { StorageNosqlService } from './storage-nosql.service';
import { validate, validateSync } from 'class-validator';
import { isEmpty } from "lodash";

export const schema = { Auth, Login, UserProfile }

export const schema_map: Object = {
  'Auth': Auth,
  'Login': Login,
  'User': User,
  'UserProfile': UserProfile,
}
export const cached_default_schema: Object = {
  'Auth': new Auth(),
  'Login': new Login(),
  'User': new User(),
  'UserProfile': new UserProfile(),
}

@Injectable({
  providedIn: 'root'
})
export class ObjectMapperService {
  private db: any
  constructor(
    private storage: StorageNosqlService
  ) {
    this.db = this.storage.getDbInstance()
  }

  getdocschema(collection) {
    if (schema_map.hasOwnProperty(collection)) {
      return new schema_map[collection]
    }

    const errorMessage: ErrorEventInit = {
      error: new Error('Schema Not Found'),
      message: "Requested schema is not found. Please initilize the schema.",
      lineno: 23,
      filename: 'ObjectMapper.ts'
    }
    throw new ErrorEvent('ObjectMapper', errorMessage)
  }

  async validate_doc(collection, doc, sync = false) {
    if (sync) {
      validateSync(doc)
      return true
    } else {
      validate(collection, doc).then((doc) => {
        return true
      }).catch((err) => {
        throw new ErrorEvent('ObjectMapper_Validator', err)
      })
    }
    return false;
  }

  async getOneDoc(collection, selector = {}, options = {}, cached = false) {
    // Can skip validation for doctype
    // Assume all schema is initilized before using
    let collectionInstance: any = await this.storage.fetchCollectionInstance(collection)
    return collectionInstance.findOne(selector, options).fetch((data) => {
      return data
    }, (error) => {
      throw new ErrorEvent('ObjectMapper', error)
    })
  }

  async getdoc(collection, selector = {}, options = {}, cached = false) {
    // Can skip validation for doctype
    // Assume all schema is intialized before using
    let collectionInstance: any = await this.storage.fetchCollectionInstance(collection)
    return collectionInstance.find(selector, options).fetch((data) => {
      return data
    }, (error) => {
      throw new ErrorEvent('ObjectMapper', error)
    })
  }

  async updatedoc(collection, doc, base = {}, cached = false) {
    // Assume all objects are instances of class validators
    if (doc instanceof Array) {
      // Validate each doc on the array
      doc.forEach((doc) => {
        this.validate_doc(collection, doc)
      })
    } else {
      this.validate_doc(collection, doc)
    }

    let collectionInstance: any = await this.storage.fetchCollectionInstance(collection)


    if (isEmpty(base)) {
      collectionInstance.upsert(doc, (data) => {
        // Succesfull append
        return data
      }, (err) => {
        console.log({ err });
        // Error appending document
        throw new ErrorEvent('ObjectMapperUpdateDoc', err)
      })
    } else {
      collectionInstance.upsert(doc, base, (data) => {
        // Succesfull append
        return data
      }, (err) => {
        // Error appending document
        throw new ErrorEvent('ObjectMapperUpdateDoc', err)
      })
    }

  }



}
